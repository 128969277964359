import React, { useContext } from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ImpactContext } from '../../components/Layout';
import { Entity } from '@parallelpublicworks/entitysync/'
import { 
        SliderField
} from '../../components/library/entitysync'; 

const marks = [
    { value: 0, label: 'Not so good.', color: 'danger' },
    { value: 1, label: 'I did OK.', color: 'neutral' },
    { value: 2, label: 'Great!', color: 'great' },
  ];

const marks_two = [
{ value: 0, label: 'None', color: 'default' },
{ value: 1, label: 'Danger', color: 'danger' },
{ value: 2, label: 'Warning', color: 'warning' },
{ value: 3, label: 'Neutral', color: 'neutral' },
{ value: 4, label: 'Good', color: 'good' },
{ value: 5, label: 'Great', color: 'great' },
];

const marks_three = [
{ value: 0, label: 'None' },
{ value: 1, label: 'Just One' },
{ value: 2, label: 'A Couple' },
{ value: 3, label: 'Three'},
];

function inputText() {
    const {user} = useContext(ImpactContext)
    return (
        <LibraryWrapper>
            <Entity componentId="user-entity" type="user--user" source={user}>
                <SliderField 
                    field="input_slider_basic" 
                    label="How did it go?"
                    marks={marks}
                />
                <hr />
                <SliderField 
                    field="input_slider_two" 
                    label="Select a level"
                    marks={marks_two}
                />
                <hr />
                <SliderField 
                    field="input_slider_three" 
                    label="With error"
                    marks={marks_three}
                    error
                    helperText="Please select an option" 
                    valueLabelDisplay
                />
                 
                    
            </Entity>
        </LibraryWrapper>
    )
}
export default inputText
